import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'

export default () => (
  <Layout>
    <SEO 
      title="The Getlocal management team"
      description="Getlocal is lead by a strong management team with many years of innovation and experience building scalable web platforms in sectors such as travel and finance." image="https://nimiuscms.imgix.net/images/coffee-splash-20190719010119.jpg?w=600&h=400&fit=crop&auto=format&q=75,format" 
      pathname="/team/"
      />
      
    <Hero 
         headerText="The Getlocal Team" 
         subHeaderText="Getlocal is lead by a strong team with years of experience"
         heroBanner="/img/londrangar.jpg" /> 
         

    <section className="section content-pages team">
      <div className="container">
        <div className="content center intro">
          
          <p>Getlocal is lead by a strong management team with many years of innovation and experience building scalable web platforms in sectors such as travel and finance.</p>
        </div>

        <div className="columns content is-multiline margin-above">
          <div className="column is-4">
          <PreviewCompatibleImage
	                        imageInfo={{
                          image: `/img/einar.jpg`,
                          alt: `Einar Gustafsson`,
                          fluid: `maxWidth: 768, quality: 80, maxHeight: 450`,
	                        }}
	                      />
            <h3>Einar Gustafsson</h3>
            <h4 className="is-size-5">CEO & Co-founder, leading product and business development</h4>
            <p>Over 20 years' experience building online solutions and innovation in FinTech (Meniga) and TravelTech (Bokun) including six years at Meniga as VP of product management where he joined as the 2nd employee and helped grow the company from 5 employees to over 100 with a solution reaching over 50 million customers.​</p>
          </div>
          <div className="column is-4">
            
            <PreviewCompatibleImage
	                        imageInfo={{
                          image: `/img/egill.jpg`,
                          alt: `Egill Erlendsson`,
                          fluid: `maxWidth: 768, quality: 80, maxHeight: 450`,
	                        }}
	                      />
            <h3>Egill Erlendsson</h3>
            <h4>CTO & Head of development, leading architecture and infrastucture</h4>
            <p>Over 20 years’ experience in the industry. Responsible for designing and developing a large-scale ad network at Automattic for WordPress.com for the past 7 years. Previously founded and served as a CTO and lead developer for music streaming service Grapewire, working for internationally acclaimed artist such as Björk, Gorillaz and many more. Prior experience also includes Fintech as well as various startups. ​</p>
          </div>
          <div className="column is-4">
          
          <PreviewCompatibleImage
	                        imageInfo={{
                          image: `/img/emil.jpg`,
                          alt: `Emil Emilsson`,
                          fluid: `maxWidth: 768, quality: 80, maxHeight: 450`,
	                        }}
	                      />
            <h3>Emil Emilsson</h3>
            <h4>COO & Co-founder, leading accounting and finance</h4>
            <p>Holds a M.Sc. degree in Economics and Business Administration - International Marketing and Management from CBS, Copenhagen.  Experienced marketing manager for companies such as Coca Cola, Össur, and Teymi (now Advania)and the Iclandic Savings Banks. Served  as the President of IMARK, the Icelandic Marketing Association.​</p>
          </div>

          <div className="column is-6">
          
          <PreviewCompatibleImage
	                        imageInfo={{
                          image: `/img/fannar.jpg`,
                          alt: `Fannar Harðarson`,
                          fluid: `maxWidth: 768, quality: 80, maxHeight: 450`,
	                        }}
	                      />
            <h3>Fannar Harðarson</h3>
            <h4>Senior Solutions Architect</h4>
            <p>Doing websites, mobile and IT for over 20 years. Started as web developer and doing some Classic ASP on the server. Open-source supporter and a long time user. Avid user of Node.js, microservices and document-stores. I believe that DevOps are one of the most important key aspects of any running system.</p>
          </div>

          <div className="column is-6">
          
          <PreviewCompatibleImage
	                        imageInfo={{
                          image: `/img/sam.jpg`,
                          alt: `Sam Daniels`,
                          fluid: `maxWidth: 768, quality: 80, maxHeight: 450`,
	                        }}
	                      />
            <h3>Sam Daniels</h3>
            <h4>Customer Success</h4>
            <p>British born writer and poet with a keen interest in psychology and marketing working as the customer success manager for Getlocal. I spend my days helping travel companies build amazing new websites whilst writing poetry on the side and dreaming of one day having a published novel under my belt! </p>
          </div>
          
        </div>
      </div>
    </section>

  </Layout>
)
